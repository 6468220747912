@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #3f51b5;
  --secondary-color: #f50057;
  --background-color: #f5f5f5;
  --card-background: #ffffff;
  --text-color: #333333;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.app-header h1 {
  margin: 0;
  font-size: 24px;
}

nav button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

nav button:hover, nav button.active {
  background-color: rgba(255,255,255,0.2);
  border-radius: 4px;
}

.dashboard h2, .admission-form h2, .bed-monitoring h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.summary-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.card {
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.card h3 {
  margin-top: 0;
  color: var(--text-color);
  font-size: 16px;
}

.large-number {
  font-size: 36px;
  font-weight: bold;
  color: var(--primary-color);
  margin: 10px 0;
}

.detailed-info {
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.chart-container {
  margin-bottom: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: var(--primary-color);
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

form div {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

button[type="submit"] {
  background-color: var(--secondary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #d1004d;
}

.bed-monitoring .chart-container {
  margin-bottom: 30px;
}

.bed-monitoring .bed-status-table table {
  width: 100%;
  border-collapse: collapse;
}

.bed-monitoring .bed-status-table th,
.bed-monitoring .bed-status-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.bed-monitoring .bed-status-table th {
  background-color: #f2f2f2;
}



.monitoring-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;
}

.ward-chart {
  width: 48%;
  margin-bottom: 20px;
}

.bed-status-table {
  width: 35%;
}

.bed-status-table table {
  width: 100%;
  border-collapse: collapse;
}

.bed-status-table th,
.bed-status-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.bed-status-table th {
  background-color: #f2f2f2;
  color: #000; /* 테이블 헤더 텍스트 색상을 검은색으로 변경 */
}

@media (max-width: 1200px) {
  .charts-container, .bed-status-table {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ward-chart {
    width: 100%;
  }
}
