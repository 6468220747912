.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-header {
  background-color: #3f51b5;  /* 원래의 헤더 색상으로 변경 */
  color: white;  /* 헤더 텍스트 색상을 흰색으로 설정 */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.app-header h1 {
  margin: 0;
  font-size: 24px;
}

.app-header nav {
  margin-top: 10px;
}

.app-header button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: transparent;  /* 배경을 투명하게 설정 */
  color: white;  /* 텍스트 색상을 흰색으로 설정 */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.app-header button:hover {
  background-color: rgba(255, 255, 255, 0.1);  /* 호버 시 약간의 배경색 변화 */
}

.app-header button.active {
  background-color: rgba(255, 255, 255, 0.2);  /* 활성 탭에 약간의 배경색 추가 */
}

.app-main {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.content-container {
  width: 100%;
}