.bed-monitoring .notifications {
  margin-bottom: 20px;
}

.bed-monitoring .notification {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.bed-monitoring .notification.discharge {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.bed-monitoring .notification.admission {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.bed-monitoring .notification.cleaning {
  background-color: #fff7e6;
  border: 1px solid #ffd591;
}

.bed-monitoring .ward-overview {
  margin-bottom: 20px;
}

.bed-monitoring .ward-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bed-monitoring .ward-chart {
  width: 48%;
  margin-bottom: 20px;
}

.bed-monitoring .bed-details table {
  width: 100%;
  border-collapse: collapse;
}

.bed-monitoring .bed-details th,
.bed-monitoring .bed-details td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.bed-monitoring .bed-details th {
  background-color: #f2f2f2;
  color: #000; /* 헤더 텍스트를 검정색으로 변경 */
}

.bed-monitoring .bed-details tr.occupied {
  background-color: #ffebee;
}

.bed-monitoring .bed-details tr.available {
  background-color: #e8f5e9;
}

.bed-monitoring .bed-details tr.cleaning {
  background-color: #fff3e0;
}

@media (max-width: 768px) {
  .bed-monitoring .ward-chart {
    width: 100%;
  }
}

.bed-monitoring .section-title {
  font-size: 1.5em;  /* 글자 크기 증가 */
  color: #333;  /* 진한 글자색 */
  margin-top: 30px;  /* 위쪽 여백 증가 */
  margin-bottom: 20px;  /* 아래쪽 여백 증가 */
  padding-bottom: 10px;  /* 아래쪽 내부 여백 추가 */
  border-bottom: 2px solid #3f51b5;  /* 밑줄 추가 */
  font-weight: bold;  /* 글자 굵기 증가 */
}

.bed-monitoring .ward-overview,
.bed-monitoring .bed-details {
  margin-top: 40px;  /* 섹션 간 여백 증가 */
}

.bed-monitoring .notifications {
  background-color: #f5f5f5;  /* 알림 섹션 배경색 추가 */
  padding: 20px;  /* 내부 여백 추가 */
  border-radius: 5px;  /* 모서리 둥글게 */
  margin-bottom: 30px;  /* 아래쪽 여백 추가 */
}

.bed-monitoring .notifications ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.bed-monitoring .notifications li {
  margin-bottom: 10px;  /* 알림 항목 간 여백 */
}

.bed-monitoring .ward-overview select {
  margin-bottom: 20px;  /* 선택 박스 아래 여백 추가 */
  padding: 8px;  /* 선택 박스 내부 여백 */
  font-size: 1em;  /* 글자 크기 */
}

.bed-monitoring table {
  margin-top: 20px;  /* 테이블 위 여백 */
}

.bed-monitoring .ward-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bed-monitoring .ward-chart {
  width: 48%;  /* 두 열로 배치 */
  margin-bottom: 20px;
}

.bed-monitoring .ward-chart h4 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #3f51b5;
}

@media (max-width: 768px) {
  .bed-monitoring .ward-chart {
    width: 100%;  /* 모바일에서는 한 열로 */
  }
}


.bed-monitoring .ward-charts.single-chart {
  justify-content: center;
}

.bed-monitoring .ward-charts.single-chart .ward-chart {
  width: 60%;
}

@media (max-width: 768px) {
  .bed-monitoring .ward-chart,
  .bed-monitoring .ward-charts.single-chart .ward-chart {
    width: 100%;
  }
}