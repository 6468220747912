.dashboard .summary-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard .card {
  width: 18%;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard .card h3 {
  font-size: 1em;
  margin-bottom: 5px;
  color: #333;
}

.dashboard .large-number {
  font-size: 1.8em;
  font-weight: bold;
  color: #0066cc;
}

.dashboard .dashboard-section {
  margin-bottom: 30px;
}

.dashboard .filter-controls {
  margin-bottom: 15px;
}

.dashboard .filter-controls select {
  padding: 5px 10px;
  font-size: 1em;
}

@media (max-width: 768px) {
  .dashboard .card {
    width: 48%;
  }
}

.content-container {
  padding: 20px;
}

.content-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #3f51b5;
  font-weight: bold;
}

.summary-section,
.chart-section,
.form-section {
  margin-top: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}